import React from 'react';
import Landing from '../components/Landing';
const md = require('markdown-it')({ html: true });
const dedent = require('dedent-js');

const content = md.render(dedent`# Certification Développement de site Web et d'application Mobile

La formation Développeur Web et Mobile, [à temps plein](https://www.lereacteur.io/bootcamp-javascript/formation-developpeur-Web-Mobile/) ou [à temps partiel](https://www.lereacteur.io/bootcamp-javascript/formation-developpeur-Web-Mobile-temps-partiel/), dispensée par Le Reacteur permet de passer la certification Développement de site Web et d'application Mobile délivrée par le Reacteur. Sur cette page sont mentionnées les informations importantes concernant la certification.

<p class="alert alert-info">🏆 Le taux de réussite à la certification Développement de site Web et d'application Mobile est de 85%.</p>

## Référentiel

La certification Développement de site Web et d'application Mobile est référencée au Répertoire Spécifique, sous le numéro RS3729. Le référentiel complet peut être consulté à l'adresse suivante : <a target="_blank" aria-label="France Compétences" href="https://www.francecompetences.fr/recherche/rs/3729/" rel="noopener noreferrer">francecompetences.fr/recherche/rs/3729</a>.

Initialement créée en 2016 pour une durée de 5 ans, la certification a été progressivement remplacée par le [titre RNCP de niveau 6 (Bac+3/4) de Concepteur Développeur d'Applications](https://www.lereacteur.io/titre-rncp-concepteur-developpeur-d-applications).

## Résumé de la certification

L'obtention de la certification Développement de site Web et d'application Mobile a pour but d'attester la validation de la formation Développeur Web et Mobile, à [temps plein](https://www.lereacteur.io/bootcamp-javascript/formation-developpeur-web-mobile/) ou à [temps partiel](https://www.lereacteur.io/bootcamp-javascript/formation-developpeur-web-mobile-temps-partiel/) dispensée par Le Reacteur. La validation du test technique constituant certification est un des prérequis pour le passage de l'examen du [titre RNCP de niveau 6 (Bac+3/4) de Concepteur Développeur d'Applications](https://www.lereacteur.io/titre-rncp-concepteur-developpeur-d-applications).

La certification permet également au candidat de se distinguer sur le marché de l'emploi auprès des entreprises, en mettant en avant sa capacité à utiliser le langage JavaScript, ainsi que ses bibliothèques Node.js, React et React Native. À la sortie de la formation, il pourra postuler pour un poste de développeur Web et/ou Mobile en tant que stagiaire ou en CDI/free-lance.

## Déroulement de la certification

Le candidat reçoit un test technique Full-Stack à réaliser sur une durée maximum de 9 jours, à compter du dernier jour de la formation. Cet exercice s'effectue à la maison, en conditions réelles. Un rendu devra être effectué auprès du Reacteur et le candidat sera évalué selon un barème précis.

L'obtention de la certification permet au candidat de posséder son attestation de réussite, qu'il peut insérer à sa guise dans son CV et sur son profil LinkedIn.
`);

const Page = React.memo(({ location }) => {
  return (
    <Landing
      location={location}
      content={[{ type: 'md', content }]}
      pageName="Certification Développement de site Web et d'application Mobile"
      metaTitle="Certification Développement de site Web et d'application Mobile | Le Reacteur"
    />
  );
});

export default Page;
